@use 'styles/breakpoints' as *;

.formSelectorWrapper {
  .formSelectorInnerWrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .formSelectorList {
    border-top: 0.125rem solid var(--clr-dark-gray-faded);

    @include desktop {
      border-top-width: 0.25rem;
    }

    .formSelectorItem {
      .formSelectorItemAddon {
        overflow: hidden;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0;
        justify-content: center;
        position: relative;
        align-items: center;
        display: flex;

        @include desktop {
          transition: width var(--transition-duration) ease;
          width: 2.25rem;
          height: 2.25rem;
        }

        .growingArrow {
          position: absolute;
          right: 0;
        }
      }

      &:hover {
        .formSelectorItemAddon {
          @include desktop {
            width: 4rem;
          }
        }
      }
    }
  }

  .formHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1.5rem;

    @include desktop {
      padding-bottom: 3rem;
    }

    & > button {
      padding: 0;
    }
  }

  .subtitleWrapper {
    margin-bottom: 2rem;
    position: relative;

    @include desktop {
      top: -1rem;
    }
  }
}
